import React from "react";
import { TimeDisplay } from "@civicsource/ui";

const TimeRange = ({ classes, startDate, endDate }) => (
	<span className={classes.time}>
		<TimeDisplay time={startDate} itemProp="startDate" showDate={false} />
		{" - "}
		<TimeDisplay time={endDate} itemProp="endDate" showDate={false} />
	</span>
);

export default TimeRange;
