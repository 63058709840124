import { bootstrap } from "toetag";
import { COLOR_BRAND_RED } from "../../colors";
import tc from "tinycolor2";

const inHoveredLink = "$blockLink:hover &, $blockLink:focus &";

export default {
	container: {
		position: "relative",
		marginBottom: bootstrap.paddingLargeVertical
	},
	mapThumb: {
		width: 60,
		paddingTop: bootstrap.paddingBaseVertical
	},
	mapThumbImg: {
		width: 48,
		height: 48,
		"& .polygon": {
			fill: "#888",
			stroke: "#555",
			transition: ".2s",
			strokeWidth: "0.02"
		},
		"& .map-thumbnail-county-active": {
			fill: "#c00",
			stroke: "#800"
		},
		"a:hover & .polygon": {
			opacity: 0.5
		},
		"a:hover & .map-thumbnail-county-active": {
			fill: "#f00",
			stroke: "#000",
			opacity: 1
		}
	},
	date: {
		fontWeight: 600,
		textTransform: "uppercase",
		color: bootstrap.linkColor,
		[inHoveredLink]: {
			color: bootstrap.linkColorHover
		}
	},
	blockLink: {
		display: "flex",
		"&, &:hover, &:focus": { textDecoration: "none" }
	},
	header: {
		color: bootstrap.textColor,
		[inHoveredLink]: { color: "#000" }
	},
	time: {
		composes: "text-muted",
		[inHoveredLink]: { color: bootstrap.textColor },
		fontWeight: 400
	},
	count: {
		fontWeight: 600,
		color: COLOR_BRAND_RED,
		[inHoveredLink]: {
			color: tc(COLOR_BRAND_RED)
				.darken(10)
				.toHexString()
		}
	},
	delimiter: { color: "#b3b3b3", composes: "hidden-sm" },
	separator: {
		marginBottom: 0,
		borderColor: "rgba(0,0,0,0.08)"
	}
};
