import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import UpcomingSales from "../content/upcoming-sales";

const CalendarPage = ({ data }) => {
	const sales = convertNodes(get(data, "allCivicSourceSales")).filter(
		s => s.code != "dummy" // fucking "dummy" nodes?? https://github.com/thinhle-agilityio/gatsby-source-apiserver#dummy-node
	);

	return <UpcomingSales sales={sales} />;
};

function convertNodes(all) {
	const nodes = get(all, "edges");
	if (!nodes) return [];
	return nodes.map(n => n.node);
}

export default CalendarPage;

export const pageQuery = graphql`
	query CalendarQuery {
		allCivicSourceSales(
			sort: { fields: [startDate, endDate, size], order: [ASC, ASC, DESC] }
		) {
			edges {
				node {
					id
					taxAuthorityName
					taxAuthorityCode
					code
					state
					stateCode
					startDate
					endDate
					size
					saleLocation
					isOffline
					type
					countyFipsCode
					stateFipsCode
					cityFipsCode
					biddingStatus
					saleName
					searchUrl
					fields {
						mapSVG
					}
				}
			}
		}
	}
`;
