import React from "react";
import { TimeDisplay } from "@civicsource/ui";
import moment from "moment";
import { formatNumber } from "accounting";
import { Link } from "gatsby";
import useSheet from "react-jss";
import styles from "./sale.style";
import TimeRange from "./time-range";

const Sale = ({
	taxAuthorityName,
	startDate,
	endDate,
	size,
	type,
	state,
	stateCode,
	searchUrl,
	containerComponent: Container = "div",
	fields: { mapSVG },
	classes
}) => {
	const verboseType =
		type === "Adj"
			? "Adjudicated Property Auctions"
			: "Tax Certificate Auctions";

	const saleName = `${taxAuthorityName} ${moment(startDate).format(
		"MMMM"
	)} ${verboseType}`;
	const singular = type === "Adj" ? "Property" : "Investment";
	const plural = type === "Adj" ? "Properties" : "Investments";
	return (
		<Container
			className={classes.container}
			itemScope
			itemType="http://schema.org/SaleEvent"
		>
			<Link
				className={classes.blockLink}
				to={searchUrl.replace("/search", "/auctions")}
			>
				<div className={classes.mapThumb}>
					<div
						className={classes.mapThumbImg}
						dangerouslySetInnerHTML={{ __html: mapSVG }}
					/>
				</div>
				<div>
					<h4 className={classes.header}>
						{taxAuthorityName}, {stateCode}
					</h4>
					<meta itemProp="name" content={saleName} />
					<span
						itemProp="location"
						itemScope
						itemType="http://schema.org/PostalAddress"
					>
						<meta itemProp="address" content="Multiple" />
						<meta itemProp="addressLocality" content={taxAuthorityName} />
						<meta itemProp="addressRegion" content={state} />
					</span>
					<div className="small">
						<span className={classes.date}>
							<TimeDisplay
								showTime={false}
								showYear={false}
								time={startDate}
								longDateFormat={"MMMM D"}
							/>
						</span>
						<Bar {...{ classes }} />
						<TimeRange {...{ classes, startDate, endDate }} />
						<Bar {...{ classes }} />
						<span className={classes.count}>
							{formatNumber(size)} {size === 1 ? singular : plural}
						</span>
					</div>
				</div>
			</Link>
			<hr className={classes.separator} />
		</Container>
	);
};

export default useSheet(styles)(Sale);

const Bar = ({ classes }) => (
	<>
		<br className="visible-sm" />
		<span className={classes.delimiter}>{" | "}</span>
	</>
);
