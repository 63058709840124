import React from "react";
import { Grid, Row, Col, Panel } from "react-bootstrap";

import Sale from "./sale";
import "../../layout/less/views/home/upcoming.less";
import { Helmet } from "react-helmet";

const UpcomingSales = ({ sales }) => (
	<Grid>
		<Helmet>
			<title>Calendar</title>
		</Helmet>
		<Row>
			<Col>
				<Panel>
					<Row>
						<Grid>
							<Row>
								<Col md={12}>
									{sales.map(({ id, ...rest }) => (
										<Sale key={id} {...rest} />
									))}
								</Col>
							</Row>
						</Grid>
					</Row>
				</Panel>
			</Col>
		</Row>
	</Grid>
);

export default UpcomingSales;
